import type { HelpCenterArticleDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterArticleDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<HelpCenterArticleDelete>) {
	return tx.del(`${workspaceId}/helpCenterArticles/${args.id}`)
}
