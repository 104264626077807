import type { ReadonlyJSONValue } from "replicache"

import type { WorkspacesGet, WorkspaceUpdate } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function workspaceUpdate({
	tx,
	args,
}: MutationContext<WorkspaceUpdate>) {
	const key = `workspaces/${args.id}`
	const prev = (await tx.get(key)) as WorkspacesGet[number] | null

	if (!prev) {
		throw new Error("Workspace does not exist")
	}

	const data: WorkspacesGet[number] = {
		...prev,
		...args,
		customerPortalSettings: args.customerPortalSettings,
		// ? args.customerPortalSettings
		// : prev.customerPortalSettings,
		roadmapSettings: args.roadmapSettings
			? {
					sortOrder: args.roadmapSettings.sortOrder,
				}
			: prev.roadmapSettings,
		selectedTeamIds: args.selectedTeamIds
			? (Object.entries(args.selectedTeamIds)
					.map(([key, value]) => (value ? key : undefined))
					.filter(Boolean) as Array<string>)
			: prev.selectedTeamIds,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
