import type { ReadonlyJSONValue } from "replicache"

import type {
	CompaniesGet,
	CustomerCreate,
	CustomerGet,
} from "@productlane/api"

import type { MutationContext } from "../../types"

export async function customerCreate({
	tx,
	args,
	workspaceId,
}: MutationContext<CustomerCreate>) {
	const key = `${workspaceId}/customers/${args.id}`

	if (await tx.has(key)) {
		throw new Error("Customer already exists")
	}

	const now = new Date()

	const data: CustomerGet = {
		createdAtIso: now.toISOString(),
		isDeleted: false,
		size: null,
		revenue: null,
		tierId: null,
		tierName: null,
		statusId: null,
		statusName: null,
		statusColor: null,
		logoUrl: null,
		domains: [],
		...args,
		workspaceId,
	}

	if (args.companyId) {
		const companyKey = `${workspaceId}/companies/${args.companyId}`
		const prev = (await tx.get(companyKey)) as unknown as
			| CompaniesGet[number]
			| null
		if (!prev || typeof prev !== "object") {
			throw new Error("Company not found to update")
		}
		await tx.set(companyKey, {
			...prev,
			customerId: args.id,
		} as unknown as ReadonlyJSONValue)
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
