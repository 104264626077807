import type { HelpCenterArticlesGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function helpCenterArticlesGet({
	tx,
	workspaceId,
}: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/helpCenterArticles/` })
		.values()
		.toArray()

	return (values as unknown as HelpCenterArticlesGet).sort(
		(a, b) => a.order - b.order,
	)
}
