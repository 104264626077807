export enum ThumbnailMimeType {
	JPEG = "image/jpeg",
	JPG = "image/jpg",
	PNG = "image/png",
	PDF = "application/pdf",
	CSV = "text/csv",
	WEBP = "image/webp",
	GIF = "image/gif",
}

export interface ValidatedAttachments {
	Content: string
	ContentLength?: string | number | null
	Name: string
	ContentType: string
	ContentID?: string
}

export function isAttachmentImage(type: string): boolean {
	return [
		ThumbnailMimeType.PNG,
		ThumbnailMimeType.JPG,
		ThumbnailMimeType.JPEG,
		ThumbnailMimeType.WEBP,
		ThumbnailMimeType.GIF,
	].includes(type as ThumbnailMimeType)
}
