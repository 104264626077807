import { mergeAttributes, Node, nodeInputRule } from "@tiptap/core"

export interface VideoOptions {
	HTMLAttributes: Record<string, unknown>
}

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		video: {
			setVideo: (options: { src: string; label?: string }) => ReturnType
		}
	}
}

export const inputRegex =
	/(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/

export const VideoExtension = Node.create<VideoOptions>({
	name: "video",
	group: "block",
	selectable: true,
	draggable: true,
	atom: true,

	addOptions() {
		return {
			inline: false,
			HTMLAttributes: {},
		}
	},

	addAttributes() {
		return {
			src: {
				default: null,
			},
			label: {
				default: null,
			},

			style: {
				default: "aspect-ratio:16/9;",
			},
			controls: {
				default: true,
				renderHTML: (attributes) => {
					if (attributes.controls) {
						return { controls: "" }
					} else {
						return {}
					}
				},
			},
			autoplay: {
				default: false,
				renderHTML: (attributes) => {
					// … and return an object with HTML attributes.
					if (attributes.autoplay) {
						return { autoplay: "" }
					} else {
						return {}
					}
				},
			},
			muted: {
				default: true,
				renderHTML: (attributes) => {
					// … and return an object with HTML attributes.
					if (attributes.muted) {
						return { muted: "" }
					} else {
						return {}
					}
				},
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: "video[src]",
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			"video",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
		]
	},

	addCommands() {
		return {
			setVideo:
				(options) =>
				({ commands }) => {
					return commands.insertContent({
						type: this.name,
						attrs: options,
					})
				},
		}
	},

	addInputRules() {
		return [
			nodeInputRule({
				find: inputRegex,
				type: this.type,
				getAttributes: (match) => {
					const [, , label, src] = match

					return {
						src,
						ariaLabel: label,
					}
				},
			}),
		]
	},
})
