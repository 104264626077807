import type { ReadonlyJSONValue } from "replicache"

import type {
	HelpCenterGroupCreate,
	HelpCenterGroupsGet,
} from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterGroupCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<HelpCenterGroupCreate>) {
	const key = `${workspaceId}/helpCenterGroups/${args.id}`

	if (await tx.has(key)) {
		throw new Error("HelpCenterGroup already exists")
	}

	const data: HelpCenterGroupsGet[number] = {
		...args,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
