import { useMedia } from "react-use"

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)"

type ThemeModes = "light" | "dark" | undefined

interface UseDarkModeOutput {
	isDarkMode: boolean
	setDarkMode: (mode: ThemeModes) => void
}

export function useDarkMode(): UseDarkModeOutput {
	const isDarkOS = useMedia(COLOR_SCHEME_QUERY, false)

	const setDarkMode = (mode: ThemeModes) => {
		switch (mode) {
			case "dark":
				document.documentElement.classList.add("dark")
				document.documentElement.classList.remove("light")
				break
			case "light":
				document.documentElement.classList.add("light")
				document.documentElement.classList.remove("dark")
				break
			default:
				document.documentElement.classList.add(isDarkOS ? "dark" : "light")
				document.documentElement.classList.remove(isDarkOS ? "light" : "dark")
				break
		}
	}

	return {
		isDarkMode: isDarkOS,
		setDarkMode,
	}
}
