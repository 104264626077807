import type { ReadonlyJSONValue } from "replicache"
import * as R from "remeda"

import type { IssueLink, IssuesGet, ThreadsGet } from "@productlane/api"

import type { MutationContext } from "../../types"
import { threadGet } from "../threads/getId"

export async function issueLink({
	tx,
	workspaceId,
	args,
}: MutationContext<IssueLink>) {
	const linearIssueId = args.linearIssue.linearTicketId

	const key = `${workspaceId}/issues/${args.issueId}`
	const issues = (await tx
		.scan({ prefix: `${workspaceId}/issues/` })
		.values()
		.toArray()) as unknown as IssuesGet

	const existing = issues.find(
		(issue) => issue.linearTicketId === linearIssueId,
	)
	if (existing) {
		const payload: IssuesGet[number] = {
			...existing,
			isVisible: existing.isVisible ?? (args.isVisible || false),
			feedbackIds: R.uniq([...existing.feedbackIds, args.feedbackId]),
		}

		await tx.set(
			`${workspaceId}/issues/${existing.id}`,
			payload as unknown as ReadonlyJSONValue,
		)
		// just update the linked feedback
		const feedback = await threadGet({
			workspaceId,
			tx,
			args: { id: args.feedbackId },
		})
		if (!feedback) {
			throw new Error("Could not find feedback")
		}
		const newFeedback: ThreadsGet[number] = {
			...feedback,
			linkedIssueIds: R.uniq([...feedback.linkedIssueIds, existing.id]),
			linkedLinearIssueIds: R.uniq([
				...feedback.linkedLinearIssueIds,
				linearIssueId,
			]),
		}
		await tx.set(
			`${workspaceId}/threads/${feedback.id}`,
			newFeedback as unknown as ReadonlyJSONValue,
		)
	} else {
		if (args.type === "linear") {
			const now = new Date()
			const data: IssuesGet[number] = {
				id: args.issueId,
				...args.linearIssue,
				createdAtIso: now.toISOString(),
				updatedAtIso: now.toISOString(),
				description: args.linearIssue.description ?? null,
				feedbackIds: [args.feedbackId],
				identifier: args.linearIssue.identifier,
				isVisible: args.isVisible ?? null,
				kluGuid: null,
				projectId: args.linearIssue.projectId ?? null,
				publicDescription: null,
				publicTitle: null,
				sortOrder: 0,
				summaryUpdatedAtIso: null,
				dueDateIso: args.linearIssue.dueDate?.toISOString() ?? null,
				summary: [],
				workspaceId,
			}
			await tx.set(key, data as unknown as ReadonlyJSONValue)
		}
		const feedback = await threadGet({
			workspaceId,
			tx,
			args: { id: args.feedbackId },
		})
		if (!feedback) {
			throw new Error("Could not find feedback")
		}
		const newFeedback: ThreadsGet[number] = {
			...feedback,
			linkedIssueIds: R.uniq([...feedback.linkedIssueIds, args.issueId]),
			linkedLinearIssueIds: R.uniq([
				...feedback.linkedLinearIssueIds,
				linearIssueId,
			]),
			linkedImportantIssueIds: R.uniq([
				...feedback.linkedImportantIssueIds,
				...(args.customerNeed.priority === 1 ? [args.issueId] : []),
			]),
		}
		await tx.set(
			`${workspaceId}/threads/${feedback.id}`,
			newFeedback as unknown as ReadonlyJSONValue,
		)
	}
}
