import type { HelpCenterGroupsGet } from "@productlane/api"

import type { QueryContext } from "../../types"

export async function helpCenterGroupGet({
	tx,
	workspaceId,
}: QueryContext<object>) {
	const values = await tx
		.scan({ prefix: `${workspaceId}/helpCenterGroups/` })
		.values()
		.toArray()

	return values as unknown as HelpCenterGroupsGet
}
