import type { IconType } from "@productlane/ui"
import {
	HighPriorityIcon,
	LowPriorityIcon,
	MediumPriorityIcon,
	NoPriorityIcon,
	UrgentPriorityIcon,
} from "@productlane/ui"

export const ALLOWED_FILE_SIZE_IN_MB = 30

export const issueStatusOrder = [
	"backlog",
	"unstarted",
	"started",
	"completed",
	"canceled",
	"triage",
]

export const projectStatusOrder = [
	"backlog",
	"planned",
	"started",
	"paused",
	"completed",
	"canceled",
]

export const LinearPriorityIcon: Record<number, IconType> = {
	0: NoPriorityIcon,
	1: UrgentPriorityIcon,
	2: HighPriorityIcon,
	3: MediumPriorityIcon,
	4: LowPriorityIcon,
}
