import { Extension } from "@tiptap/core"
import Suggestion from "@tiptap/suggestion"

import type { CustomBodyParams, SlashCommandsOptions } from "./types"

export default Extension.create<SlashCommandsOptions<CustomBodyParams>>({
	name: "slash-commands",

	addOptions() {
		return {
			suggestion: {
				char: "/",
				command: ({ editor, range, props }) => {
					props.command({ editor, range, customBodyParams: {} })
				},
			},
		}
	},

	addProseMirrorPlugins() {
		return [
			Suggestion({
				editor: this.editor,
				...this.options.suggestion,
			}),
		]
	},
})
