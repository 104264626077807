import HeadingExtension from "@tiptap/extension-heading"
import ImageExtension from "@tiptap/extension-image"
import ItalicExtension from "@tiptap/extension-italic"
import Link from "@tiptap/extension-link"
import StarterKit from "@tiptap/starter-kit"

import type { Extensions } from "@tiptap/core"
import { DisableCommandEnter } from "./extensions"
import { CustomTaskItemExtension } from "./extensions/custom-task-item-extension"
import { CustomTaskListExtension } from "./extensions/custom-task-list-extension"
import { EmExtension } from "./extensions/em-extension"
import { EnterAsBreakExtension } from "./extensions/enter-as-break-extension"
import { HardBreakExtension } from "./extensions/hard-break-extension"
import { HighlightExtension } from "./extensions/highlight-extension"
import { MessageExtension } from "./extensions/message-extension"
import { StrongExtension } from "./extensions/strong-extension"
import { TimestampExtension } from "./extensions/timestamp-extension"

export function getDefaultExtensions({
	image = true,
	heading = true,
	history = true,
	messageExtension = true,
	timestampExtension = true,
	enableCmdEnter = false,
	enterAsBreakeExtension = false,
}: {
	image?: boolean
	heading?: boolean
	history?: boolean
	messageExtension?: boolean
	timestampExtension?: boolean
	enableCmdEnter?: boolean
	enterAsBreakeExtension?: boolean
}): Extensions {
	const exts: Extensions = [
		StarterKit.configure({
			italic: false,
			...(history ? {} : { history: false }), // disable history (undo/redo)
			heading: false,
		}),
		ItalicExtension.extend({
			addKeyboardShortcuts() {
				return {
					// ↓ your new keyboard shortcut
					"Mod-i": () => {
						return this.editor.commands.toggleItalic()
					},
					"Mod-I": () => {
						return false
					},
				}
			},
		}),
		// LinkExtension.extend({
		// 	addKeyboardShortcuts() {
		// 		return {
		// 			Space: () => {
		// 				if (
		// 					this.editor &&
		// 					this.editor.isFocused &&
		// 					this.editor.isActive("link")
		// 				) {
		// 					this.editor.chain().focus().unsetMark("link").run()
		// 				}

		// 				return false
		// 			},
		// 		}
		// 	},
		// }),
		Link.configure({}),

		HighlightExtension.configure({ multicolor: true }),
		CustomTaskItemExtension.configure({}),
		CustomTaskListExtension.configure({}),
		EmExtension.configure({}),
		HardBreakExtension.configure({}),
		StrongExtension.configure({}),
	]
	if (!enableCmdEnter) {
		exts.push(DisableCommandEnter)
	}
	if (enterAsBreakeExtension) {
		exts.push(EnterAsBreakExtension.configure({}))
	}
	if (image) {
		exts.push(ImageExtension.configure({}))
	}
	if (heading) {
		exts.push(HeadingExtension.configure({}))
	}
	if (messageExtension) {
		exts.push(MessageExtension)
	}
	if (timestampExtension) {
		exts.push(TimestampExtension)
	}

	return exts
}

export { DisableCommandEnter } from "./extensions/disable-command-enter"

export const HeadingExtensionWithoutH1 = HeadingExtension.extend({
	// there should only be one H1 on a page, so we render H1 in the changelog as H2
	renderHTML({ HTMLAttributes, node }) {
		return [
			node.attrs.level === 1 ? "h2" : `h${node.attrs.level}`,
			HTMLAttributes,
			0,
		]
	},
})

export { VideoExtension } from "./extensions/video-extension"

export { MessageExtension } from "./extensions/message-extension"
