import type { ReadonlyJSONValue } from "replicache"

import type {
	HelpCenterArticlesGet,
	HelpCenterArticleUpdate,
} from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterArticleUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<HelpCenterArticleUpdate>) {
	const key = `${workspaceId}/helpCenterArticles/${args.id}`

	const prev = (await tx.get(key)) as unknown as
		| HelpCenterArticlesGet[number]
		| null

	if (!prev || typeof prev !== "object") {
		throw new Error("HelpCenterArticle not found to update")
	}

	const newArticle: HelpCenterArticlesGet[number] = {
		...prev,
		archived: args.archived ?? prev.archived,
		content: args.content ? args.content : prev.content,
		published: args.published ? args.published : prev.published,
		title: args.title ? args.title : prev.title,
		icon: args.icon ? args.icon : prev.icon,
		imageUrl: args.imageUrl ? args.imageUrl : prev.imageUrl,
		imageBlurhash: args.imageBlurhash ? args.imageBlurhash : prev.imageBlurhash,
		order: args.order ?? prev.order,
		helpCenterGroupId: args.helpCenterGroupId ?? prev.helpCenterGroupId,
	}

	return tx.set(key, newArticle as unknown as ReadonlyJSONValue)
}
