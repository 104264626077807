import { useSubscribe } from "replicache-react"

import type { ReplicacheMutations, WorkspacesGet } from "@productlane/api"

import { helpCenterGroupGet } from "../procedures"
import { useDataStoreActions } from "../store"

export function useHelpCenterGroupsSubscribe({
	replicache,
	workspace,
}: {
	replicache: ReplicacheMutations | null
	workspace: WorkspacesGet[number] | undefined
}) {
	const { setHelpCenterGroups } = useDataStoreActions()

	useSubscribe(
		replicache,
		async (tx) => {
			if (workspace) {
				const groups = await helpCenterGroupGet({
					tx,
					workspaceId: workspace.id,
					args: {},
				})
				setHelpCenterGroups(groups)
				return groups
			}
			return []
		},
		{ default: [], dependencies: [workspace] },
	)
}
