import type { ReadonlyJSONValue } from "replicache"

import type {
	HelpCenterGroupsGet,
	HelpCenterGroupUpdate,
} from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterGroupUpdate({
	tx,
	args,
	workspaceId,
}: MutationContext<HelpCenterGroupUpdate>) {
	const key = `${workspaceId}/helpCenterGroups/${args.id}`

	const prev = (await tx.get(key)) as unknown as
		| HelpCenterGroupsGet[number]
		| null

	if (!prev || typeof prev !== "object") {
		throw new Error("HelpCenterGroup not found to update")
	}

	const newGroup: HelpCenterGroupsGet[number] = {
		...prev,
		...args,
	}

	return tx.set(key, newGroup as unknown as ReadonlyJSONValue)
}
