import type { ReadonlyJSONValue } from "replicache"

import type {
	HelpCenterArticleReorder,
	HelpCenterArticlesGet,
} from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterArticleReorder({
	tx,
	args,
	workspaceId,
}: MutationContext<HelpCenterArticleReorder>) {
	// Update each group with its new order
	const updates = args.items.map(async ({ id, order }) => {
		const key = `${workspaceId}/helpCenterArticles/${id}`

		const prev = (await tx.get(key)) as unknown as
			| HelpCenterArticlesGet[number]
			| null

		if (!prev || typeof prev !== "object") {
			throw new Error("HelpCenterArticle not found to update")
		}

		const newArticle: HelpCenterArticlesGet[number] = {
			...prev,
			order,
		}

		return tx.set(key, newArticle as unknown as ReadonlyJSONValue)
	})

	// Wait for all updates to complete
	await Promise.all(updates)
}
