export const generateHeadlineKey = (
	headline: string,
	index: number,
): string => {
	return `${index}-${headline
		.toLowerCase()
		.replace(/[^a-z0-9]+/g, "-")
		.replace(/(^-|-$)/g, "")}`
}

export const generateUniqueHeadlineKeys = (
	headlines: string[],
): { key: string; headline: string }[] => {
	return headlines.map((text, index) => ({
		key: generateHeadlineKey(text, index),
		headline: text,
	}))
}

export const generateUrlName = (title?: string): string => {
	const res = (title ?? "")
		.toLowerCase()
		.replace(/[^a-z0-9]+/g, "-")
		.replace(/(^-|-$)/g, "")
	return res === "" ? "untitled" : res
}
