import type { ReadonlyJSONValue } from "replicache"

import type {
	HelpCenterArticleCreate,
	HelpCenterArticlesGet,
} from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterArticleCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<HelpCenterArticleCreate>) {
	const key = `${workspaceId}/helpCenterArticles/${args.id}`

	if (await tx.has(key)) {
		throw new Error("HelpCenterArticle already exists")
	}

	const data: HelpCenterArticlesGet[number] = {
		...args,
		archived: false,
		content: "",
		summary: "",
		urlName: args.id,
		imageUrl: null,
		imageBlurhash: null,
		published: false,
		icon: "FileText",
		workspaceId,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
