import type { HtmlToTextOptions } from "html-to-text"
import { convert } from "html-to-text"

const convertOptions: HtmlToTextOptions = {
	formatters: {
		boldFormatter: function (elem, walk, builder) {
			builder.addInline("**")
			walk(elem.children, builder)
			builder.addInline("**")
		},
	},
	selectors: [
		{ selector: "img", format: "skip" },
		{ selector: "a", options: { ignoreHref: true } },
		{ selector: "h1", options: { uppercase: false } },
		{ selector: "h2", options: { uppercase: false } },
		{ selector: "h3", options: { uppercase: false } },
		{ selector: "h4", options: { uppercase: false } },
		{ selector: "b", format: "boldFormatter" },
		{ selector: "div", options: { trailingLineBreaks: 2 } },
		{ selector: "p", options: { trailingLineBreaks: 2 } },
	],
	preserveNewlines: true,
}

function removeEmptyParagraphs(html: string) {
	return html.replace(/<p>\s*<\/p>/g, "")
}

export function convertHtmlToText(html: string, removeEmptyP?: boolean) {
	return convert(
		removeEmptyP ? removeEmptyParagraphs(html) : html,
		convertOptions,
	)
}

// The expression below matches timestamps and formats them as bulletpoints, with the timestamp in parentheses.
// Try it out: https://regex101.com/r/aAtX2f/1
export function convertTimestampsToBulletpoints(html: string) {
	const convertedHtml = html.replace(
		/<timestamp([^>]*)data-timestamp-time="([^"]*)"(.*?)>(.*?)<\/timestamp>/g,
		"<ul><li>($2): $4</li></ul>",
	)
	return convertedHtml
}

export function removeAllBrTags(html: string) {
	return html.replace(/<br\s*\/?>/gi, "")
}
