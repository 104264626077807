import type { Config } from "tailwindcss"

import baseConfig from "@productlane/tailwind-config"

export default {
	corePlugins: {
		// we'll copy the relevant parts to not interfere with radix
		preflight: false,
	},
	content: [
		"index.html",
		"./src/**/*.{ts,tsx}",
		"../../packages/ui/src/**/*.{ts,tsx}",
	],
	presets: [baseConfig],
	theme: {
		extend: {
			fontFamily: {
				sans: ["var(--font-inter)"],
			},
			borderWidth: {
				DEFAULT: ".5px",
			},
			ringWidth: {
				"1": "0.5px",
			},
			borderColor: {
				DEFAULT: "blue",
			},
			fontWeight: {
				normal: "450",
			},
			fontSize: {
				xxs: "0.6875rem",
				xs: "0.8125rem",
				sm: "0.8425rem",
			},
			colors: {
				gray: {
					25: "#FCFCFC",
					500: "#707476",
					700: "#323334",
					800: "#1B1C1D",
					850: "#151718",
					900: "#101111",
				},
			},
		},
	},
	//to make the font 450 instead of 400 by default
	plugins: [
		function ({ addBase }: { addBase: (styles: Record<string, any>) => void }) {
			addBase({
				"*, ::before, ::after": {
					fontWeight: "inherit",
				},
				body: {
					fontWeight: 450,
				},
			})
		},
	],
	safelist: ["bg-amber-100", "dark:bg-amber-900"],
} satisfies Config
