import parse from "node-html-parser"
import { pipe } from "remeda"
import { z } from "zod"

import type { Email } from "@productlane/db"
import { convertHtmlToText } from "@productlane/lib/src/convert-html"

export const EmailContactSchema = z.object({
	name: z.string(),
	email: z.string(),
	mailboxHash: z.string(),
})
export const EmailAttachmentSchema = z.object({
	Content: z.string(),
	ContentLength: z.string().or(z.number()).optional().nullable(),
	Name: z.string(),
	ContentType: z.string(),
	ContentID: z.string().optional(),
})

export type EmailContact = z.infer<typeof EmailContactSchema>
export type EmailAttachment = z.infer<typeof EmailAttachmentSchema>

export const splitEmailChunks = (
	emailHtmlContent: string,
): [string, string] => {
	if (!emailHtmlContent) return ["", ""]

	let hiddenContent = ""

	const parsed = parse(emailHtmlContent).removeWhitespace()

	const chunks: [string, string] = [parsed.toString(), ""]

	const quotesToHide = [
		// "blockquote",
		".gmail_quote",
		"[class$='gmail_quote']",
		".gmail_signature_prefix",
		"[class$='gmail_signature_prefix']",
		"[class$='gmail_signature']",
		".gmail_signature",
		"#Signature",
		".ms-outlook-mobile-signature",
		"#mail-editor-reference-message-container",
		".x-apple-transform-wrapper",
		"[class^='main-style-'] > .front-signature",
		"[name='messageSignatureSection']",
		"[name='messageReplySection']",
		".shortwave-signature",
		"#shortwave-signature",
		".protonmail_signature_block",
		".protonmail_signature_block-empty",
		".protonmail_quote",
		".productlane-quote",
		"front-signature",
	]

	//* we need to hide the first blockquote to prevent duplicating the hidden content
	const blockquotes = parsed.querySelectorAll("blockquote")

	if (blockquotes.length > 0) {
		// store only the outermost blockquote
		hiddenContent += blockquotes[0]?.toString() ?? ""
		// remove all blockquotes from the email body
		blockquotes.forEach((bq) => bq.remove())
	}

	quotesToHide.forEach((selector: string) => {
		const selectedItems = parsed.querySelectorAll(selector)
		selectedItems.forEach((m) => {
			hiddenContent += m.toString() ?? ""
			m.remove()
		})
	})

	//outlook
	const forwardMessage = parsed.querySelector("#appendonsend")
	if (forwardMessage) {
		const parent = forwardMessage.parentNode
		if (parent) {
			let nextElement = forwardMessage.nextElementSibling
			while (nextElement) {
				if (nextElement.tagName === "DIV" || nextElement.tagName === "HR") {
					hiddenContent += nextElement.toString()
					const currentElement = nextElement
					nextElement = nextElement.nextElementSibling
					currentElement.remove()
				} else {
					nextElement = nextElement.nextElementSibling
				}
			}
		}
		hiddenContent += forwardMessage.toString()
		forwardMessage.remove()
	}

	const wordSection = parsed.querySelector("[class^='WordSection']")
	if (wordSection) {
		parsed
			.querySelectorAll("[class^='WordSection'] > div")
			.forEach((element) => {
				hiddenContent += element.toString()
				element.remove()
			})
	}

	const msOutlookMobileSignatureId = parsed.querySelector(
		"#ms-outlook-mobile-signature",
	)
	if (msOutlookMobileSignatureId) {
		let nextElement = msOutlookMobileSignatureId.nextElementSibling

		hiddenContent += msOutlookMobileSignatureId.toString()
		msOutlookMobileSignatureId.remove()

		// we need to loop through all the elements below the outlook signature
		while (nextElement) {
			hiddenContent += nextElement.toString()
			const sibling = nextElement.nextElementSibling
			nextElement.remove()
			nextElement = sibling
		}
	}

	//removing our signature
	const signatureStart = parsed.querySelector('p:contains("--")')
	if (signatureStart) {
		let nextElement = signatureStart.nextElementSibling

		hiddenContent += signatureStart.toString()
		signatureStart.remove()

		// we need to loop through all the elements below our signature
		while (nextElement) {
			hiddenContent += nextElement.toString()
			const sibling = nextElement.nextElementSibling
			nextElement.remove()
			nextElement = sibling
		}
	}

	//removing hollow images that left by superhuman
	const images = parsed.querySelectorAll("img")
	images.forEach((image) => {
		const width = image.getAttribute("width")
		const height = image.getAttribute("height")

		if (width === "1" && height === "0") {
			image.remove()
		} else if (height === "1" && width === "0") {
			image.remove()
		}
	})

	const regExp = /<div><\/div>|<p><\/p>|<div><br><\/div>/g
	const brRegExp = /<br>(?=(<[^<>]+?>)*$)/g //this is just genius regexp 🤯
	const regExpSuperhuman =
		/<br><\/div><\/div><div><div><img[^>]*><\/div><br><\/div><br><br><\/div><\/div>/g
	const regExpEmptyImage = /<img[^>]*\s+src\s*=\s*['"][^'"]*['"][^>]*>/g

	chunks[0] = parsed
		.toString()
		.replace(regExp, "")
		.replace(regExpSuperhuman, "")
		.replace(regExpEmptyImage, "")
		.replace(brRegExp, "")

	chunks[1] = hiddenContent.replace(regExp, "")
	return chunks
}

export const populateEmailAttachments =
	(attachments: EmailAttachment[]) => (htmlContent: Email["htmlContent"]) =>
		htmlContent.replace(/src="cid:([^"]+)"/g, (_, p1: string) => {
			// `_` is the whole matched string, e.g., 'src="cid:someid"'
			// `p1` is the first captured group, the 'someid' part after 'cid:'
			const attachment = attachments.find((a) => a.ContentID?.includes(p1))

			return attachment
				? `src="data:${attachment.ContentType};base64,${attachment.Content}"`
				: 'src=""'
		})

const EMAIL_CONTENT_CHARS_LIMIT = 1000

export const formatEmailHtmlContent = (emailContent: string) =>
	pipe(
		emailContent,
		(content) => content.replace(/<https?:\/\/\S+>/g, ""),
		(content) => content.replace(/https?:\/\/\S+/g, ""),
		(content) => splitEmailChunks(content)[0],
		convertHtmlToText,
		(content) =>
			content.length > EMAIL_CONTENT_CHARS_LIMIT
				? `${content.slice(0, EMAIL_CONTENT_CHARS_LIMIT)}...`
				: content,
	)
