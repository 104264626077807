import type { ReadonlyJSONValue } from "replicache"

import type { ThreadCreate, ThreadsGet } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function threadCreate({
	tx,
	workspaceId,
	args,
}: MutationContext<ThreadCreate>) {
	const key = `${workspaceId}/threads/${args.id}`
	if (await tx.has(key)) {
		throw new Error("Thread already exists")
	}

	const now = new Date()

	const data: ThreadsGet[number] = {
		id: args.id,
		createdAtIso: now.toISOString(),
		workspaceId,
		lastInboundMessageAtIso: null,
		lastOutboundMessageAtIso: null,
		lastStateChangeAtIso: null,

		painLevel: args.painLevel ?? "UNKNOWN",
		state: args.state ?? "NEW",
		text: args.text ?? "",
		reporterId: args.reporterId ? args.reporterId : null,
		showRecordCall: false,
		origin: "INAPP",

		companyId: null,
		companyName: null,

		contactId: null,
		contactEmail: null,
		contactImageUrl: null,
		contactName: null,

		slackChannelId: null,

		frontId: null,
		hubspotId: null,
		intercomId: null,
		zendeskId: null,

		recordingId: null,
		videoId: null,

		linearAttachmentId: null,

		linkedIssueIds: [],
		linkedImportantIssueIds: [],
		linkedLinearIssueIds: [],
		linkedProjectIds: [],
		linkedCommentIds: [],

		tagIds: [],

		title: args.title ?? "New thread",
		assigneeId: args.assigneeId ?? null,
	}

	return tx.set(key, data as unknown as ReadonlyJSONValue)
}
