export const CHURN_TYPES = [
	"PLAN_DOWNGRADE",
	"DELETE_WORKSPACE",
	"UNPUBLISH_PORTAL",
] as const

export type ChurnType = (typeof CHURN_TYPES)[number]

export const DIALOG_TITLES: Record<ChurnType, string> = {
	PLAN_DOWNGRADE: "Are you sure you want to downgrade your plan?",
	DELETE_WORKSPACE: "Help us improve",
	UNPUBLISH_PORTAL: "Are you sure you want to unpublish your portal?",
} as const

export const CHURN_REASONS = [
	"Some feature are missing",
	"Productlane is too expensive",
	"We don't have enough feedback",
	"We have too much feedback which hard to handle",
	"Too many performance issues or bugs",
	"Productlane is too complicated to use",
	"Other",
] as const
