import type { HelpCenterGroupDelete } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterGroupDelete({
	tx,
	args,
	workspaceId,
}: MutationContext<HelpCenterGroupDelete>) {
	return tx.del(`${workspaceId}/helpCenterGroups/${args.id}`)
}
