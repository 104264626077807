import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"

const baseEditorClasses =
	"prose-h1:text-[1.5rem] prose-a:no-underline hover:prose-a:underline hover:prose-a:cursor-pointer prose-a:text-green-600 dark:prose-a:text-green-500 prose-h1:font-bold prose-h2:text-[1.25rem] prose-h3:text-[1.125rem] prose-h4:text-[1rem] prose-h5:text-[1rem] prose-h6:text-[1rem] max-w-none"

export const typographyVariants = cva(
	"prose dark:prose-invert prose-a:break-words prose-a:text-green-600 prose-a:no-underline hover:prose-a:underline hover:prose-a:cursor-pointer dark:prose-a:text-green-500",
	{
		variants: {
			variant: {
				editor:
					"prose-h1:text-[1.5rem] prose-h1:font-bold prose-h2:text-[1.25rem] prose-h3:text-[1.125rem] prose-h4:text-[1rem] prose-h5:text-[1rem] prose-h6:text-[1rem] max-w-none",
				blog: "prose-h2:mt-12 prose-h2:tracking-tight prose-h3:tracking-tight prose-h2:mb-4 prose-h2:font-semibold prose-h3:pt-2 prose-h3:text-[1.25rem] prose-p:pb-1 prose-p:text-[1rem] prose-p:font-normal prose-a:cursor-pointer",
				changelog: "",
				portalDescription:
					"prose-h1:text-[1.5rem] prose-h1:font-bold prose-h2:text-[1.25rem] prose-h3:text-[1.125rem] prose-h4:text-[1rem] prose-h5:text-[1rem] prose-h6:text-[1rem] max-w-none text-sm text-tertiary font-medium",
				message: `${baseEditorClasses} text-[0.9375rem] [&>.is-empty]:text-tertiary text-primary break-words text-wrap flex-wrap`,
			},
		},
	},
)

export type typographyVariantsType = VariantProps<
	typeof typographyVariants
>["variant"]
