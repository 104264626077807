import type { ReadonlyJSONValue } from "replicache"

import type {
	HelpCenterGroupReorder,
	HelpCenterGroupsGet,
} from "@productlane/api"

import type { MutationContext } from "../../types"

export async function helpCenterGroupReorder({
	tx,
	args,
	workspaceId,
}: MutationContext<HelpCenterGroupReorder>) {
	// Update each group with its new order
	const updates = args.items.map(async ({ id, order }) => {
		const key = `${workspaceId}/helpCenterGroups/${id}`

		const prev = (await tx.get(key)) as unknown as
			| HelpCenterGroupsGet[number]
			| null

		if (!prev || typeof prev !== "object") {
			throw new Error("HelpCenterGroup not found to update")
		}

		const newGroup: HelpCenterGroupsGet[number] = {
			...prev,
			order,
		}

		return tx.set(key, newGroup as unknown as ReadonlyJSONValue)
	})

	// Wait for all updates to complete
	await Promise.all(updates)
}
