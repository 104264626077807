import type { Config } from "tailwindcss"

import baseConfig from "@productlane/tailwind-config"

export default {
	darkMode: "class",
	content: [
		"./src/**/*.{ts,tsx}",
		"../../packages/ui/src/**/*.{ts,tsx}",
		// needed for "aspect-video" in the Youtube extension
		"../../packages/api/src/lib/portal.ts",
	],
	presets: [baseConfig],
	theme: {
		extend: {
			screens: {
				xls: "1150px",
			},
			ringWidth: {
				"1": "0.5px",
			},
			borderWidth: {
				DEFAULT: ".5px",
			},
		},
	},
} satisfies Config
