import * as cheerio from "cheerio"

export const removeStyleAttributes = (html: string) =>
	html.replace(/\s(?:style|color)=["'][^"']*["']/g, "")

export const addTargetBlankToLinks = (html: string) => {
	const $ = cheerio.load(html)
	$("a").each((_, link) => {
		$(link).attr("target", "_blank")
		$(link).attr("rel", "noopener noreferrer")
	})
	return $.html()
}

export const findAllHeadlines = (content: string | null): string[] => {
	if (!content) return []
	const $ = cheerio.load(content)
	const headlines = $("h1").toArray()
	return Array.from(headlines)
		.map((headline) => $(headline).text())
		.filter((h) => h)
}
